// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.obstacle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
  
  .obstacle {
    position: absolute;
    width: 70px;
    height: 70px;
    pointer-events: none;
  }
  
  .obstacle-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
  }`, "",{"version":3,"sources":["webpack://./src/pages/game/obstacle_system.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,WAAW;IACX,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;EACtB","sourcesContent":[".obstacle-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10;\n    pointer-events: none;\n  }\n  \n  .obstacle {\n    position: absolute;\n    width: 70px;\n    height: 70px;\n    pointer-events: none;\n  }\n  \n  .obstacle-image {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    pointer-events: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
