class CollisionSystem {
    constructor() {
      this.travelerBounds = {
        width: 25,
        height: 100,
        offsetX: 55,
        offsetY: 15
      };
  
      this.obstacleBounds = {
        width: 30,    
        height: 50,   
        offsetX: 13, 
        offsetY: 10  
      };
    }
  
    getTravelerCollisionRect() {
      const travelerElement = document.querySelector('.game_traveler');
      if (!travelerElement) return null;
  
      const rect = travelerElement.getBoundingClientRect();
      return {
        x: rect.left + this.travelerBounds.offsetX,
        y: rect.top + this.travelerBounds.offsetY,
        width: this.travelerBounds.width,
        height: this.travelerBounds.height
      };
    }
  
    getObstacleCollisionRect(obstacleElement) {
      const rect = obstacleElement.getBoundingClientRect();
      return {
        x: rect.left + this.obstacleBounds.offsetX,
        y: rect.top + this.obstacleBounds.offsetY,
        width: this.obstacleBounds.width,
        height: this.obstacleBounds.height
      };
    }
  
    checkCollisions(travelerPosition, obstacles) {
      const travelerRect = this.getTravelerCollisionRect();
      if (!travelerRect) return false;
      
      return obstacles.some(obstacle => {
        const obstacleElement = document.querySelector(`[data-obstacle-id="${obstacle.id}"]`);
        if (!obstacleElement) return false;
  
        const obstacleRect = this.getObstacleCollisionRect(obstacleElement);
        return this.checkCollision(travelerRect, obstacleRect);
      });
    }
  
    checkCollision(rect1, rect2) {
      return (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
      );
    }
}
  
const collisionSystem = new CollisionSystem();
export default collisionSystem;