import React, { useRef, useCallback, useEffect } from 'react';
import './traveler.css';
import { isTMA } from '@tma.js/sdk';

const Traveler = ({ 
  gameSpeed, 
  onInteraction, 
  energy, 
  earnPerTap, 
  travelerGameSprite,
  onPositionUpdate = () => {}
}) => {
  const travelerRef = useRef(null);
  const isTouchDeviceRef = useRef(false);
  const containerRef = useRef(null);
  
  useEffect(() => {
    const updatePosition = () => {
      if (travelerRef.current) {
        const rect = travelerRef.current.getBoundingClientRect();
        onPositionUpdate({
          x: rect.left + rect.width / 2,
          y: rect.top + rect.height / 2
        });
      }
    };

    const observer = new ResizeObserver(updatePosition);
    if (travelerRef.current) {
      observer.observe(travelerRef.current);
      updatePosition();
    }

    return () => observer.disconnect();
  }, [onPositionUpdate]);

  const handleTouchStart = useCallback(() => {
    isTouchDeviceRef.current = true;
  }, []);

  const handleTouchEnd = useCallback((e) => {
    if (containerRef.current && energy >= earnPerTap) {
      const rect = containerRef.current.getBoundingClientRect();
      for (let touch of e.changedTouches) {
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;
        
        if (!travelerRef.current?.classList.contains('jump-animation')) {
          if (travelerRef.current) {
            travelerRef.current.classList.add('shrink-animation');
            setTimeout(() => {
              travelerRef.current.classList.remove('shrink-animation');
            }, 200);
            
            travelerRef.current.classList.add('jump-animation');
            const jumpDuration = parseFloat(getComputedStyle(travelerRef.current).getPropertyValue('--jump-duration')) * 1000;
            setTimeout(() => {
              travelerRef.current.classList.remove('jump-animation');
            }, jumpDuration);
          }

          if (isTMA() && window.Telegram?.WebApp?.HapticFeedback) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
          }

          onInteraction(x, y);
        }
      }
    }
  }, [energy, earnPerTap, onInteraction]);

  const handleMouseUp = useCallback((e) => {
    if (!isTouchDeviceRef.current && containerRef.current && energy >= earnPerTap) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (!travelerRef.current?.classList.contains('jump-animation')) {
        if (travelerRef.current) {
          travelerRef.current.classList.add('shrink-animation');
          setTimeout(() => {
            travelerRef.current.classList.remove('shrink-animation');
          }, 200);
          
          travelerRef.current.classList.add('jump-animation');
          const jumpDuration = parseFloat(getComputedStyle(travelerRef.current).getPropertyValue('--jump-duration')) * 1000;
          setTimeout(() => {
            travelerRef.current.classList.remove('jump-animation');
          }, jumpDuration);
        }

        if (isTMA() && window.Telegram?.WebApp?.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }

        onInteraction(x, y);
      }
    }
  }, [energy, earnPerTap, onInteraction]);

  return (
    <div 
      className="game_traveler-container"
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={(e) => e.preventDefault()}
      onMouseUp={handleMouseUp}
    >
      <div className="game_traveler-clickable-area" />
      <div
        className="game_traveler"
        ref={travelerRef}
        style={{
          '--traveler-sprite': `url(${travelerGameSprite})`,
          '--sprite-speed': `${1 / gameSpeed.sprite}s`
        }}
      />
    </div>
  );
};

export default Traveler;