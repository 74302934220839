import React, { useState, useEffect, useCallback, useRef } from 'react';
import './obstacle_system.css';
import collisionSystem from './collision_system';

const obstacles = Array.from({ length: 9 }, (_, i) => {
  const num = String(i + 1).padStart(2, '0');
  return require(`../../assets/images/obstacle/obstacle${num}.png`);
});

const MIN_DELAY = 700;

const ObstacleSystem = ({ 
  speedFactor = 0.01, 
  travelerPosition = null,
  onCollision = () => {},
  isPaused = false
}) => {
  const [activeObstacles, setActiveObstacles] = useState([]);
  const lastGenerateTime = useRef(0);
  const animationFrameId = useRef();
  const lastUpdateTime = useRef(Date.now());
  const generationTimeoutId = useRef();

  // 檢查碰撞
  useEffect(() => {
    if (!isPaused && travelerPosition && activeObstacles.length > 0) {
      try {
        const hasCollision = collisionSystem.checkCollisions(travelerPosition, activeObstacles);
        if (hasCollision) {
          onCollision();
        }
      } catch (error) {
        console.error('Collision check error:', error);
      }
    }
  }, [travelerPosition, activeObstacles, onCollision, isPaused]);

  const scheduleNextObstacle = useCallback(() => {
    if (isPaused) return;

    const nextDelay = Math.random() * 2000 + 1000;
    generationTimeoutId.current = setTimeout(() => {
      const currentTime = Date.now();
      if (currentTime - lastGenerateTime.current >= MIN_DELAY) {
        const randomObstacle = obstacles[Math.floor(Math.random() * obstacles.length)];
        const newObstacle = {
          id: currentTime,
          position: {
            x: -50,
            y: 360
          },
          image: randomObstacle
        };
        
        setActiveObstacles(prev => [...prev, newObstacle]);
        lastGenerateTime.current = currentTime;
      }
      if (!isPaused) {
        scheduleNextObstacle();
      }
    }, nextDelay);
  }, [isPaused]);

  const updateObstacles = useCallback(() => {
    if (!isPaused) {
      const currentTime = Date.now();
      const deltaTime = (currentTime - lastUpdateTime.current) / 16;
      const moveSpeed = speedFactor * 300 * deltaTime;

      setActiveObstacles(prev => 
        prev
          .map(obstacle => ({
            ...obstacle,
            position: {
              ...obstacle.position,
              x: obstacle.position.x + moveSpeed
            }
          }))
          .filter(obstacle => obstacle.position.x < window.innerWidth + 50)
      );

      lastUpdateTime.current = currentTime;
    }
    animationFrameId.current = requestAnimationFrame(updateObstacles);
  }, [speedFactor, isPaused]);

  useEffect(() => {
    if (!isPaused) {
      scheduleNextObstacle();
    }
    animationFrameId.current = requestAnimationFrame(updateObstacles);
    
    return () => {
      if (generationTimeoutId.current) {
        clearTimeout(generationTimeoutId.current);
      }
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [scheduleNextObstacle, updateObstacles, isPaused]);

  return (
    <div className="obstacle-container">
      {activeObstacles.map(obstacle => (
        <div
          key={obstacle.id}
          data-obstacle-id={obstacle.id}
          className="obstacle"
          style={{
            transform: `translate3d(${obstacle.position.x}px, ${obstacle.position.y}px, 0)`,
            position: 'absolute'
          }}
        >
          <img
            src={obstacle.image}
            alt="obstacle"
            className="obstacle-image"
          />
        </div>
      ))}
    </div>
  );
};

export default ObstacleSystem;