import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import './tap_game.css';
import Traveler from './traveler';
import { GameInfo, ClickEffect, LevelBar, EnergyBar, LevelUpModal } from './tap_game_ui';
import { levelData, countries } from './game_model';
import { levelUp } from './tap_game_api';
import { GameContext } from './game_provider';
import { MusicContext } from '../../music_provider';
import travelerGameSprite from '../../assets/traveler_game/traveler_game.png';
import ObstacleSystem from './obstacle_system';

const originalWidth = 1600;

const GameState = {
  NOT_STARTED: 'NOT_STARTED',
  PLAYING: 'PLAYING',
  COLLIDED: 'COLLIDED'
};

const CollisionScreen = ({ onRestart }) => (
  <div className="game-overlay">
    <div className="game-modal">
      <h2 className="game-modal-title"> You Suck!</h2>
      <p className="game-modal-text">
        My grandma could play better than you.
      </p>
      <button
        className="game-start-button"
        onClick={onRestart}
      >
        Try again?
      </button>
    </div>
  </div>
);

const StartScreen = ({ onStart }) => (
  <div className="game-overlay">
    <div className="game-modal">
      <h2 className="game-modal-title">Ready to Travel?</h2>
      <p className="game-modal-text">
        Tap to jump and avoid obstacles!
      </p>
      <button
        className="game-start-button"
        onClick={onStart}
      >
        Start Adventure!
      </button>
    </div>
  </div>
);


const TapGame = ({ onBoost, onMenu }) => {
  const { user, setUser, energy, setEnergy, addToIncrAmount, isLoading } = useContext(GameContext);
  const { setMusic } = useContext(MusicContext);
  const [score, setScore] = useState(user.token_amount);
  const [travelerLevel, setTravelerLevel] = useState(user.user_level);
  const [tapScoreLevel, setTapScoreLevel] = useState(user.tap_to_earn_level);
  const [clickEffects, setClickEffects] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldCallOnMenu, setShouldCallOnMenu] = useState(false);
  const [gameState, setGameState] = useState(GameState.NOT_STARTED);

  const [gameSpeed, setGameSpeed] = useState({
    scroll: 0.01,
    sprite: 1
  });
  const [travelerPosition, setTravelerPosition] = useState(null);
  const [obstacles, setObstacles] = useState([]);

  const tapgameRef = useRef(null);
  const maxEnergy = levelData.energyRecovery[user.energy_level].maxEnergy;
  const backgroundImage = countries[user.current_country]?.map;
  const earnPerTap = levelData.traveler[travelerLevel - 1].earnPerTap + levelData.tapScore[tapScoreLevel].earnPerTap;
  const backgroundPositionRef = useRef(0);
  const animationFrameRef = useRef(null);

  const animateBackground = useCallback(() => {
    if (gameState === GameState.PLAYING) {
      backgroundPositionRef.current -= gameSpeed.scroll * -200;
      const backgroundElement = document.querySelector('.background-map-wrapper');
      if (backgroundElement) {
        backgroundElement.style.backgroundPositionX = `${backgroundPositionRef.current}px`;
      }
      animationFrameRef.current = requestAnimationFrame(animateBackground);
    }
  }, [gameState, gameSpeed.scroll]);

  useEffect(() => {
    if (gameState === GameState.PLAYING) {
      animationFrameRef.current = requestAnimationFrame(animateBackground);
    } else if (gameState === GameState.COLLIDED) {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [gameState, animateBackground]);

  const handleCollision = useCallback(() => {
    setGameState(GameState.COLLIDED);
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setGameSpeed({
      scroll: 0,
      sprite: 0
    });
  }, []);

  // Update startGame to reset background position
  const startGame = useCallback(() => {
    setGameState(GameState.PLAYING);
    backgroundPositionRef.current = 0;
    setGameSpeed({
      scroll: 0.01,
      sprite: 1
    });
    setObstacles([]);
  }, []);

  const restartGame = useCallback(() => {
    setGameState(GameState.NOT_STARTED);
    setGameSpeed({
      scroll: 0.01,
      sprite: 1
    });
    setObstacles([]);

    setTimeout(() => {
      startGame();
    }, 0);
  }, [startGame]);

  const handleUpdateScore = useCallback(() => {
    if (energy >= earnPerTap) {
      const newEnergy = Math.max(0, energy - earnPerTap);
      setEnergy(newEnergy);

      const newScore = score + earnPerTap;
      let newLevel = travelerLevel;

      if (newScore >= levelData.traveler[newLevel - 1].nextLevelXP) {
        newLevel += 1;
        const requireAmount = levelData.traveler[travelerLevel - 1].nextLevelXP;
        const type = "user";

        setIsModalVisible(true);
        setShouldCallOnMenu(true);
        setTravelerLevel(newLevel);

        levelUp(requireAmount, newLevel, type).then(() => {
          setUser(prevUser => ({
            ...prevUser,
            user_level: newLevel
          }));
        });
      }

      setScore(newScore);
      addToIncrAmount(earnPerTap);
      setUser(prevUser => ({
        ...prevUser,
        token_amount: newScore
      }));
    }
  }, [energy, earnPerTap, travelerLevel, score, setUser, setEnergy, addToIncrAmount]);

  const handleInteraction = useCallback((x, y) => {
    if (gameState !== GameState.PLAYING) return;

    const newEffects = [{ id: Date.now() + Math.random(), x, y }];
    setClickEffects(prevEffects => [...prevEffects, ...newEffects]);
    handleUpdateScore();

    setTimeout(() => {
      setClickEffects(prevEffects => prevEffects.filter(effect =>
        !newEffects.find(newEffect => newEffect.id === effect.id)
      ));
    }, 1000);
  }, [handleUpdateScore, gameState]);


  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);

    setUser(prevUser => {
      const updatedSelectedCountries = [...(prevUser.selected_countries || [])];
      if (!updatedSelectedCountries.includes(prevUser.current_country)) {
        updatedSelectedCountries.push(prevUser.current_country);
      }

      return {
        ...prevUser,
        selected_countries: updatedSelectedCountries
      };
    });

    if (shouldCallOnMenu) {
      onMenu();
      setShouldCallOnMenu(false);
    }
  }, [setUser, onMenu, shouldCallOnMenu]);

  const isMaxLevel = travelerLevel === levelData.traveler.length;
  const nextLevelXP = isMaxLevel ? 0 : levelData.traveler[travelerLevel - 1].nextLevelXP;

  useEffect(() => {
    setMusic(countries[user.current_country]?.bgm);
  }, [user.current_country, setMusic]);

  useEffect(() => {
    setScore(user.token_amount);
  }, [user.token_amount]);

  useEffect(() => {
    setTravelerLevel(user.user_level);
  }, [user.user_level]);

  useEffect(() => {
    setTapScoreLevel(user.tap_to_earn_level);
  }, [user.tap_to_earn_level]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tap-game" ref={tapgameRef}>
      {(gameState === GameState.PLAYING || gameState === GameState.COLLIDED) && (
        <ObstacleSystem
          speedFactor={gameSpeed.scroll}
          travelerPosition={travelerPosition}
          onCollision={handleCollision}
          isPaused={gameState === GameState.COLLIDED}
          obstacles={obstacles}
          setObstacles={setObstacles}
        />
      )}
      <div className="background-map-container">
      <div
          className="background-map-wrapper"
          style={{
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
            backgroundRepeat: 'repeat-x',
            backgroundSize: 'auto 100%',
            width: originalWidth * 2,
            backgroundPositionX: `${backgroundPositionRef.current}px`
          }}
        />
      </div>
      <div style={{ height: '32px' }} />
      <GameInfo
        earnPerTap={earnPerTap}
        nextLevelXP={nextLevelXP}
        isMaxLevel={isMaxLevel}
        score={score}
      />
      <Traveler
        gameSpeed={gameSpeed}
        onInteraction={handleInteraction}
        energy={energy}
        earnPerTap={earnPerTap}
        travelerGameSprite={travelerGameSprite}
        onPositionUpdate={setTravelerPosition}
        gameState={gameState}
      />
      {clickEffects.map(effect => (
        <ClickEffect key={effect.id} x={effect.x} y={effect.y} earnPerTap={earnPerTap} />
      ))}
      <LevelBar
        level={travelerLevel}
        score={score}
        nextLevelXP={nextLevelXP}
        levelTitles={levelData.traveler.map(level => level.title)}
        isMaxLevel={isMaxLevel}
      />
      <div style={{ height: '26px' }} />
      <EnergyBar
        currentEnergy={energy}
        maxEnergy={maxEnergy}
        onBoost={onBoost}
      />
      <div style={{ height: '12px' }} />

      {gameState === GameState.NOT_STARTED && (
        <StartScreen onStart={startGame} />
      )}
      {gameState === GameState.COLLIDED && (
        <CollisionScreen onRestart={restartGame} />
      )}

      <LevelUpModal
        isVisible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default TapGame;