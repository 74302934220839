import axios from 'axios';
import config from './config';

const BASE_URL = `https://${config.apiUrl}`; 

const apiClient = axios.create({
  baseURL: BASE_URL,
});

apiClient.interceptors.request.use((config) => {
  const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIyMDU4NjIwNTQ5IiwiZXhwIjoxNzU4NjE1ODkwLCJqdGkiOiI0NTE5NzAwYy04OGZlLTQyMGQtYTZkNS1jMmE2ZGJlM2JhMTkiLCJpYXQiOjE3MjI2MTU4OTAsImlzcyI6InNlcnZlciIsInN1YiI6IjIwNTg2MjA1NDkifQ.ch6okwbnd0vZwaKuwdY5M95_w8PucSdcQfu4iwKpitWoz6vd8oWwgcyy6lWYcxUKf3pVzlDybTS0NK6REIX9L_r_VuJdTRZ0cybYM_MawBcYhEc96WdpKcWYBg4QX5E2t3pV4TYE_nlW_5gJZLo8Y4E18OYt4j7w9DgFpGd_7fqN698KuWFRPb6zK1srcos9iGpKglk2vjorxoIRt4KGvQt2m7zaYig65QPLxj0n_LrL0I3lLFjyI-XMZ7RzMfDisZcAfJyDuvIHmf363tBMZ1Ln7Gf1JxciOpS4MRmuJXdabMe0G70p79LqaADP9VcH2oyHNGYm0vp5alhH7uPwjhXL632BDIz3rzFc7VNJCso_MqBijPvgPo84B3nzF19hkqwN_-o1MloA_l1yrNabRLYXLZUdiFQJuqXFQu8tM7WuGGqd2iNwz5Lmba9q0leQzb4YjuZIqLJkMV5reQUnbCQHuE8bsiNbRG-hgWzWQUyD540FPpqhhfTSL4_LMp95yUH-_RoXaFweT4d6y3LKSXlaBf436KV-c_QfSlM-uw_vgu0AEl3yc9ig2H45BxMCXSccqVZWFzEad-02Fk4NIfFX7edX9jYVWC-2zMEHuouMUWelm1yiMbJw5lEvTbc_sLwUdvbfwby_i7nLN722miXE_vQssSRQNCAU-FsWQPU'
  // localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }
  console.log('Request config:', config);
  return config;
}, (error) => {
  return Promise.reject(error);
});

const apiGet = async (endpoint, params) => {
  try {
    const response = await apiClient.get(endpoint, { params });
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(`GET ${endpoint} error:`, error);
    throw error;
  }
};

const apiPost = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error(`POST ${endpoint} error:`, error);
    throw error;
  }
};

const api = {
  apiGet,
  apiPost,
};

export default api;
